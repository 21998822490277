<template>
  <div class="batch-page">
    <batch-voice-table
      ref="batchVoice"
      :invoiceVariety="invoiceVariety"
      @success="batchSuccessCallback"
      @edit="editRowCallback"
      @cancel="batchCancelCallback"
    ></batch-voice-table>
    <!-- 侧边栏弹窗展示 -->
    <erp-drawer :visible="dialog.visible" @close="closeDialog" erp :width="960" class="no-padding fix-top-header">
      <edit-invoice :row="rowData" :rowIndex="rowIndex" @cancel="cancelEdit" @editBack="editLocalBack" @success="editSuccessCallback"></edit-invoice>
    </erp-drawer>
  </div>
</template>
<script>
import { getRouterParams } from '@/utils/publicMethods';
import batchVoiceTable from './components/batchVoiceTable.vue';
import editInvoice from './components/editInvoice.vue';
export default {
  components: { batchVoiceTable, editInvoice },
  data() {
    return {
      invoiceVariety: '', // 1 货物发票   2 服务发票
      showType: 1,
      rowData: {},
      rowIndex: 0,
      dialog: {
        visible: false,
      },
    };
  },
  created() {
    const { invoiceVariety } = getRouterParams('batchPage');
    this.invoiceVariety = invoiceVariety;
  },
  methods: {
    editRowCallback(row, rowIndex) {
      this.rowData = row;
      this.dialog.visible = true;
      this.rowIndex = rowIndex;
    },
    cancelEdit() {
      this.dialog.visible = false;
    },
    closeDialog() {
      this.dialog.visible = false;
    },
    editSuccessCallback() {
      this.dialog.visible = false;
      this.$refs.batchVoice.getDatas();
    },
    editLocalBack(row, index) {
      this.dialog.visible = false;
      this.$refs.batchVoice.updateRow(row, index);
    },
    batchCancelCallback() {
      this.$router.push({ name: 'Invoice' });
    },
    batchSuccessCallback() {
      this.$router.push({ name: 'Invoice' });
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-page {
  margin-top: 20px;
}
</style>
